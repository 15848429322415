import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Panier from './pages/Panier';
import Service from './pages/Service';
import Repas from './pages/Repas';
import RepasListe from './pages/Repasliste';
import Menu from './pages/Menu';
import { connect } from 'react-redux';



const App = (props) => {

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Register />} />
					{/* <Route path='login' element={<Login />} /> */}
					{/* <Route  path='register' element={<Register />} /> */}
					{/* <Route path='panier' element={<Panier />} /> */}
					{/*<Route path='service/:id' element={<Service />} />*/}
					{/* <Route path='repas/:id' element={<Repas />} />  */}
					{/* <Route path='menulist/:id' element={<Menu />} /> */}
					{/* <Route path='repaslist/:id' element={<RepasListe />} /> */}
          {/* Liste des menu afficher depuis un scan */}
					{/* <Route path='menu/:id/:table' element={<Repas />} /> */}
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
