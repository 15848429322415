import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { uri } from "../api/api";
import { useFormik } from "formik";
import * as Yup from 'yup';

const Register = () => {

	const [loginService, setLoginService] = useState([])
	const [register, setRegister] = useState(false);
	const [insert, setInsert] = useState();
	const [serviceInfo, setServiceInfo] = useState({});

	const notify = () => toast('Compte créer avec succès');

	useEffect(() => {
		fetch(`${uri}categorie`, {
			method: 'GET'
		})
		.then( response => response.json())
		.then( data => {
			setLoginService(data)
		})
	}, [])

	useEffect(() => {
		if(insert) {

			const formData = new FormData();
      		formData.append('fullname', serviceInfo.nom);
      		formData.append('email', serviceInfo.email);
      		formData.append('pass', serviceInfo.password);
      		formData.append('phone', serviceInfo.telephone);
      		formData.append('categorie_id', serviceInfo.categorie);
      		formData.append('description', serviceInfo.description);
      		formData.append('avatar', serviceInfo.logo);
      		formData.append('couverture', serviceInfo.couverture);

			fetch(`${uri}user/register/service`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					ContentType: 'multipart/form-data',
				},
				body: formData
			})
			.then( response => response.json())
			.then( data => {
				setRegister(data)
				setInsert(false)
				notify()
			})
		}
	}, [insert])


	const validateSchema = Yup.object().shape({
		nom: Yup.string().required("Ce champ est obligatoire"),
		adresse: Yup.string().required("Ce champ est obligatoire"),
		telephone: Yup.string().required("Ce champ est obligatoire"),
		email: Yup.string().email("Entrer un email valide").required("Ce champ est obligatoire"),
		categorie: Yup.number().required("Ce champ est obligatoire"),
		description: Yup.string().required("Ce champ est obligatoire"),
		logo: Yup.mixed().required("Ce champ est obligatoire")
		.test('fileFormat', 'Seulement les fichiers image sont autorisé', value => {
			if (value) {
				const supportedFormats = ['png','gif','jpg','jpeg'];
				return supportedFormats.includes(value.name.split('.').pop());
			}
			return true;
		}),
		couverture: Yup.mixed().required("Ce champ est obligatoire")
		.test('fileFormat', 'Seulement les fichiers image sont autorisé', value => {
			if (value) {
				const supportedFormats = ['png','gif','jpg','jpeg'];
				return supportedFormats.includes(value.name.split('.').pop());
			}
			return true;
		}),
		password: Yup.string()
		  .required("Ce champ est obligatoire")
		  .min(8, "Le mot de passe doit comporter 8 caractères au moins")
		  .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Le mot de passe doit contenir au moins un caractère majuscule et un caractère minuscule.")
		  .matches(/\d/, "Le mot de passe doit contenir au moins un chiffre")
		  .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Le mot de passe doit contenir au moins un caractère spécial"),
		confirm: Yup.string().when("password", (password, field) => {
			if (password) {
				return field.required("Les mots de passe ne correspondent pas").oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas");
			}
		}),
	});
	
	const formik = useFormik({
		initialValues: {
			nom: "",
		  	adresse: "",
			email: "",
			logo: "",
			couverture: "",
			telephone: "",
			description: "",
			categorie: "",
			password: "",
			confirm: "",
		},
		validationSchema: validateSchema,
		onSubmit: (values, { resetForm }) => {
		//   console.log(values);
		  setTimeout(() => {
			resetForm();
			setServiceInfo(formik.values);
			setInsert(true);
		  }, 1000 * 2);
		},
	});

	const handleLogo = (e) => {
		formik.setFieldValue('logo', e.target.files[0]);
	}

	const handleCouverture = (e) => {
		formik.setFieldValue('couverture', e.target.files[0]);
	}

	// const ServiceRegister = () => {

	// 	return (
			
	// 	)
	// }

	// const ClientRegister = () => {
	// 	return (
	// 		<div className="flex flex-col items-center justify-center relative min-h-screen h-auto">
	// 			<div className="px-6 h-full flex w-full md:w-2/5 my-10 flex-col justify-center">
	// 				<h1 className="text-primary font-bold mb-10 text-4xl">Créer un compte</h1>

	// 				<form action="" className="space-y-4 md:space-y-6" method="POST" encType="multipart/form-data">

	// 					<div>
	// 						<label htmlFor="nom" className="block mb-2 text-sm font-medium text-gray-900">Nom et prénoms</label>
	// 						<input type="text" name="nom" value="" id="nom" className={formik.errors.nom ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} placeholder="HAY COOK" required="" />
							
	// 					</div>
	// 					<div>
	// 						<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Votre e-mail</label>
	// 						<input type="email" name="email" value="" id="email" className={formik.errors.nom ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} placeholder="name@company.com" required="" />
							
	// 					</div>
	// 					<div>
	// 						<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Mot de passe</label>
	// 						<input type="password" name="password" value="" id="password" placeholder="Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full p-4 " required="" />
							
	// 					</div>
	// 					<div>
	// 						<label htmlFor="confirm" className="block mb-2 text-sm font-medium text-gray-900">Confirmer mot de passe</label>
	// 						<input type="password" name="confirm" value="" id="confirm" placeholder="Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢Ã¢â‚¬Â¢" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full p-4 " required="" />
							
	// 					</div>
	// 					<button type="submit" className="w-full text-white bg-primary hover:bg-[#f5c57d] focus:ring-4 focus:outline-none focus:ring-[#FFF2DE] font-medium rounded-lg text-md px-5 py-4 text-center dark:bg-primary dark:hover:bg-primary ">Se connecter</button>
	// 					<p className="text-sm mt-0 font-light text-gray-500 dark:text-gray-400">
	// 						Vous avez déjÃƒÂ  un compte ? <Link to="/login" className="font-medium text-primary hover:underline dark:text-primary">Se connecter</Link>
	// 					</p>
	// 				</form>
	// 			</div>
	// 		</div>
	// 	)
	// }

    return (
		<>
			<div className="flex flex-col items-center justify-center relative min-h-screen h-auto">
				<ToastContainer />
				<div className="px-6 h-full flex w-full md:w-2/5 my-10 flex-col justify-center">
					<h1 className="text-primary font-bold mb-10 text-4xl">Créer un compte</h1>

					<form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit} encType="multipart/form-data">

						<div>
							<label htmlFor="nom" className="block mb-2 text-sm font-medium text-gray-900">Nom du service</label>
							<input 
								type="text"
								onChange={formik.handleChange}  
								value={formik.values.nom} 
								id="nom" 
								className={formik.errors.nom && formik.touched.nom ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} 
								placeholder="HAY COOK" 
							/>
							<p className="mt-2 text-red-400">{formik.errors.nom} </p>
						</div>
						<div>
							<label htmlFor="adresse" className="block mb-2 text-sm font-medium text-gray-900">Adresse</label>
							<input type="text" name="adresse" onChange={formik.handleChange}  value={formik.values.adresse} id="adresse" className={formik.errors.adresse && formik.touched.adresse ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} placeholder="Cotonou, St Michel" required="" />
							<p className="mt-2 text-red-400">{formik.errors.adresse} </p>
						</div>
						<div>
							<label htmlFor="categorie" className="block mb-2 text-sm font-medium text-gray-900">Choisissez votre catégorie</label>
							<select name="categorie" onChange={formik.handleChange}  value={formik.values.categorie} id="categorie" className={formik.errors.categorie ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "}  required="">
								{loginService.map( item => (
									<option key={item.id} value={item.id}>{item.nom_categorie}</option>
								))
								}
								
							</select>
							<p className="mt-2 text-red-400">{formik.errors.categorie} </p>
						</div>
						<div>
							<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Votre e-mail</label>
							<input type="email" name="email" onChange={formik.handleChange}  value={formik.values.email} id="email" className={formik.errors.email && formik.touched.email ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} placeholder="name@company.com" required="" />
							<p className="mt-2 text-red-400">{formik.errors.email} </p>
						</div>
						<div>
							<label htmlFor="telephone" className="block mb-2 text-sm font-medium text-gray-900">Téléphone</label>
							<input type="tel" name="telephone" onChange={formik.handleChange}  value={formik.values.telephone} id="telephone" className={formik.errors.telephone && formik.touched.telephone ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} placeholder="+229 66 00 00 00" required="" />
							<p className="mt-2 text-red-400">{formik.errors.telephone} </p>
						</div>
						<div className="flex gap-4 items-center">
							<div className="flex-grow">
								<label htmlFor="logo" className="block mb-2 text-sm font-medium text-gray-900">Logo</label>
								<input 
									type="file" 
									name="logo" 
									onChange={handleLogo}
									id="logo" 
									className="block w-full text-sm text-slate-500
									file:mr-4 file:py-2 file:px-4 file:rounded-md
									file:border-0 file:text-sm file:font-semibold
									file:bg-primary file:text-white
									hover:file:bg-[#f5c57d] " 
									required="" 
									accept="image/png,jpg,jpeg,svg"
								/>
								<p className="mt-1 pl-2 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">Image (png, jpg, jpeg)</p>
								<div>{(formik.errors.logo) ? <p className="text-red-400">{formik.errors.logo}</p> : null}</div>
							</div>
							<div className="flex-grow">
								<label htmlFor="couverture" className="block mb-2 text-sm font-medium text-gray-900">Image de couverture</label>
								<input 
									type="file" 
									name="couverture" 
									onChange={handleCouverture}
									id="couverture" 
									className="block w-full text-sm text-slate-500
									file:mr-4 file:py-2 file:px-4 file:rounded-md
									file:border-0 file:text-sm file:font-semibold
									file:bg-primary file:text-white
									hover:file:bg-[#f5c57d] "
									required=""
									accept="image/png,jpg,jpeg,svg"
								/>
								<p className="mt-1 pl-2 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">Image (png, jpg, jpeg)</p>
								<div>{(formik.errors.couverture) ? <p className="text-red-400">{formik.errors.couverture}</p> : null}</div>
							</div>
						</div>
						<div>
							<label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
							<textarea
								name="description" 
								onChange={formik.handleChange}  value={formik.values.description} 
								id="description"
								className={formik.errors.password && formik.touched.password ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} 
								required=""
							></textarea>
							<p className="mt-2 text-red-400">{formik.errors.description} </p>
						</div>
						<div>
							<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Mot de passe</label>
							<input 
								type="password" 
								name="password" 
								onChange={formik.handleChange}  value={formik.values.password} 
								id="password" 
								placeholder="•••••••••" 
								className={formik.errors.password && formik.touched.password ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} 
								required=""
							/>
							<p className="mt-2 text-red-400">{formik.errors.password} </p>
						</div>
						<div>
							<label htmlFor="confirm" className="block mb-2 text-sm font-medium text-gray-900">Confirmer mot de passe</label>
							<input 
								type="password" 
								name="confirm" 
								onChange={formik.handleChange}  value={formik.values.confirm} 
								id="confirm" 
								placeholder="•••••••••" 
								className={formik.errors.confirm && formik.touched.confirm ? "bg-gray-50 border border-red-700 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 " : "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-4 "} 
								required="" 
							/>
							<p className="mt-2 text-red-400">{formik.errors.confirm} </p>
						</div>
						<button disabled={formik.isSubmitting} type="submit" className="w-full text-white bg-primary hover:bg-[#f5c57d] focus:ring-4 focus:outline-none focus:ring-[#FFF2DE] font-medium rounded-lg text-md px-5 py-4 text-center dark:bg-primary dark:hover:bg-primary ">S'inscrire</button>
						{/* <p className="text-sm mt-0 font-light text-gray-500 dark:text-gray-400">
							Vous avez déjÃƒÂ  un compte ? <Link to="/login" className="font-medium text-primary hover:underline dark:text-primary">Se connecter</Link>
						</p> */}
					</form>
				</div>
			</div>

		</>
    )
  };
  
  export default Register;