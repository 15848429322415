import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    panier: [],
    error: ''
}

export const fetchPanier = createAsyncThunk('panier/fetchPanier', () => {
    return JSON.parse(localStorage.getItem('userCart'))
});

const panierSlice = createSlice({
    name: "panier",
    initialState,
    reducers: {
        add: (state, action) => {
            state.panier = action.payload
        },
        loading: (state) => {
            state.loading = true
        }
    },
    // extraReducers: builder => {
    //     builder.addCase(fetchPanier.pending, state => {
    //         state.loading = true
    //     })
    //     builder.addCase(fetchPanier.fulfilled, (state, action) => {
    //         state.loading = false,
    //         state.panier = action.payload,
    //         state.error = ''
    //     })
    //     builder.addCase(fetchPanier.rejected, (state, action) => {
    //         state.loading = false,
    //         state.panier = [],
    //         state.error = action.error.message
    //     })
    // }
})

export default panierSlice.reducer
export const {add} = panierSlice.actions