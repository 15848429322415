
import { configureStore } from "@reduxjs/toolkit";
import panierSlice from "./panier/panierSlice";

const store = configureStore({
    reducer: {
      panier: panierSlice
    }
})
  
export default store;