import React, { useEffect } from "react";
import bomi3 from '../images/télécharger.jpeg'
import { FiX  } from "react-icons/fi";
import { connect } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import { useSelector, useDispatch } from "react-redux";
import { add } from "../store/panier/panierSlice";

const Panier = (props) => {
	const panier = useSelector(state => state.panier.panier)
	const dispatch = useDispatch()

	const deleteRepas = (repasId) => {
		const temp = JSON.parse(localStorage.getItem('userCart'))	
		
		// Retirer l'élément du panier 
		const tempCart = temp.filter((repas => repas.id!=repasId))

		// Mettre à jour le panier
		localStorage.setItem('userCart', JSON.stringify(tempCart));

		dispatch(add(tempCart))
		
	}

	// useEffect(() => {

	// },[])

    return (
      <>
        <div className="flex flex-col md:flex-row md:flex-wrap px-5 gap-y-3 relative min-h-[calc(100vh-130px)] h-auto my-10">
			<h1 className="text-3xl text-primary self-start mb-5 font-bold">Votre Panier</h1>

			{/* {console.log(panier)} */}

			{
				panier.length ?
					panier.map(item => (
						<div key={item.id} className="w-full rounded-md bg-slate-100 shadow-md flex items-center p-2 relative">
							<div className="flex justify-center">
								<img src={bomi3} className="w-32 h-32 rounded-md" />
							</div>
							<div className="px-4 flex flex-col">
								<h1 className="text-2xl font-bold mb-4">{item.nom}</h1>
								<div className="flex gap-x-2">
									<h1 className="text-xl font-bold">Qty:</h1>
									<h1 className="text-xl font-bold text-primary">{item.quantite}</h1>
								</div>
								<div className="flex gap-x-2">
									{/* <h1 className="text-xl font-bold">Total:</h1> */}
									<h1 className="text-xl font-bold text-primary">{item.prixT} Fcfa</h1>
								</div>
							</div>
							<div onClick={()=> deleteRepas(item.id)} className="bg-red-600 z-10 absolute top-2 right-2 rounded-full p-1 cursor-pointer">
								<FiX className="text-white text-xl font-bold" />
							</div>
						</div>
					))
				:
				<div className="flex flex-col items-center justify-center gap-y-4 ">
					<Player
						src={require('../assets/empty2.json')}
						className="loading w-96"
						loop
						autoplay
					/>
					<h1 className="text-3xl font-bold mb-5">Aucu repas</h1>
				</div>
			}

			{ 	panier.length ?
				<div className="flex justify-center absolute left-0 bottom-0 w-full">
					<button type="button" class="w-11/12 text-white bg-primary hover:bg-[#f5c57d] focus:ring-4 focus:outline-none focus:ring-[#FFF2DE] font-medium rounded-lg text-md px-5 py-4 text-center dark:bg-primary dark:hover:bg-primary ">Commander</button>
				</div>
				:
				null
			}


			
        </div>
      </>
    )
};

const mapStateToProps = state => {
	return {
		panier: state.panier
	};
};
  
export default Panier;