import React, { useEffect } from "react";

import { Outlet, Link } from "react-router-dom";
import logo from '../logo/logo4.png';
import { FiSearch, FiShoppingCart } from "react-icons/fi";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { add } from "../store/panier/panierSlice";

const Layout = (props) => {
    const panier = useSelector(state => state.panier.panier)
    const dispatch = useDispatch()

    useEffect(() => {
        var temp = JSON.parse(localStorage.getItem('userCart'))
        if(temp) {
            dispatch(add(temp))
        }
    },[])

    return (
        <>
            <nav className="px-4 md:px-20 py-6 bg-[#FFF2DE] flex justify-between">
                <Link to='/' className="flex items-center">
                    <img src={logo} alt="Delice-Delice logo" className=" w-14 rounded-full" />
                    <h3 className=" text-black font-bold pl-2">Delice-Delice</h3>
                </Link>
                <div className="flex items-center gap-x-4">
                    {/* <form>
                        <div className="bg-white flex items-center justify-between rounded-[12px] pl-2 h-12 border-2 border-black">
                            <div>
                                <FiSearch className="text-2xl font-bold" />
                            </div>
                            <input type="text" className="bg-white h-11 rounded-[12px] font-['Poppins-Regular'] w-full flex-grow focus:border-0 focus:outline-0 text-xl pl-2 uppercase" />
                        </div>
                    </form> */}
                    {/* <Link to="/panier" className="bg-black h-12 w-12 rounded-[12px] flex items-center justify-center cursor-pointer relative">
                        <FiShoppingCart color="#FFFFFF" className="text-2xl font-bold" />
                        <div className="absolute -top-1 -right-2 h-5 w-5 rounded-full flex items-center justify-center bg-primary text-sm text-white" id="nbPanier">{panier.length}</div>
                    </Link> */}
                    {/* <Link to="/login" className="bg-primary text-white px-5 py-3 rounded-full font-semibold text-md hover:bg-slate-50 hover:text-black">Connexion</Link> */}

                </div>
            </nav>

            <Outlet />

            <footer className="bg-black py-7 px-10 ">
                <p className="text-white text-center">Copyright &copy; 2023, Tout droit réservé</p>
            </footer>

        </>
    )
};

export default Layout;